<template>
  <header>
    <Header />
    <view-header />
    <VideoOverview />
    <side-by-sides />
    <Timeline />
    <resources />
    <Videos />
    <FAQs />
    <Footer />
  </header>
</template>

<script>
import Header from "./employeeComponents/Header.vue";
import ViewHeader from "./employeeComponents/ViewHeader.vue";
import SideBySides from "./employeeComponents/SideBySides.vue";
import Timeline from "./employeeComponents/Timeline.vue";
import FAQs from "./employeeComponents/FAQs.vue";
import Videos from "../QuickTipVideos/CurrentVideo.vue";
import VideoOverview from "./employeeComponents/VideoOverview.vue";
import Resources from "./employeeComponents/Resources.vue";
import Footer from "./employeeComponents/Footer.vue";

export default {
  metaInfo: {
    meta: [{ name: "robots", content: "noindex" }]
  },
  components: {
    Header,
    ViewHeader,
    SideBySides,
    Timeline,
    FAQs,
    Videos,
    Footer,
    VideoOverview,
    Resources
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    isMobile: function() {
      return this.windowWidth < 992;
    }
  }
};
</script>
